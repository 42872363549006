/* 印地语 */
export default {
  emailRegister: 'ईमेल रजिस्टर',
  registerNotice:
    'अनरजिस्टर्ड खाते स्वचालित रूप से पंजीकृत होते हैं और लॉग इन होते हैं',
  enterEmail: 'कृपया अपना ईमेल पता दर्ज करें',
  enterCode: 'कृपया अपना कोड दर्ज करें',
  getCode: 'कोड प्राप्त करें',
  signIn: 'अभी साइन इन करें',
  signPropt: 'लॉग इन करके, आप',
  policy: 'गोपनीयता नीति',
  agreement: 'उपयोगकर्ता समझौता',
  emailRuleError: 'ईमेल प्रारूप गलत है',

  starcard: `स्टार कार्ड सदस्यता लाभ`,
  more: `अधिक`,
  describe: `स्टार कार्ड एक अधिकार प्रमाण है जो धन का बर्तन प्रमोशन सिस्टम के फायदे का लाभ उठाने के लिए आवश्यक है। स्टार कार्ड केवल उन्हीं के पास होता है जो इस प्रमोशन सिस्टम के सभी लाभों का आनंद ले सकते हैं। स्टार कार्ड को एक्सपीरियंस स्टार कार्ड और एक्सक्लूसिव स्टार कार्ड में विभाजित किया गया है, और स्टार कार्ड उपयोगकर्ता नकद इनाम प्राप्त कर सकते हैं।`,
  starReward: `स्टार कार्ड प्रमोशन इनाम`,
  unlock: `अनलॉक करें`,
  text1: `· आमंत्रण इनाम: उपयोगकर्ताओं को सीधे या अप्रत्यक्ष रूप से स्टार कार्ड सदस्यता खरीदने के लिए आमंत्रित करने पर 10% और 5% इनाम प्राप्त होता है।`,
  text2: `· निवेश कॉपीराइट प्रमोशन इनाम: उपयोगकर्ताओं को सीधे या अप्रत्यक्ष रूप से निवेश कॉपीराइट प्रमोशन में आमंत्रित करने पर दैनिक आय का 10% और 5% इनाम प्राप्त होता है।`,
  text3: `· फिल्म देखने का इनाम: उपयोगकर्ताओं को एक एपिसोड अनलॉक करने के लिए सीधे आमंत्रित करने पर 40% इनाम प्राप्त होता है, या मिनी-सीरीज सदस्यता लेने पर 20% इनाम प्राप्त होता है।`,
  starClu: `स्टार समूह इनाम`,
  text4: `विशिष्ट इनाम नियमों के लिए कृपया आधिकारिक कस्टमर सर्विस या आमंत्रणकर्ता से संपर्क करें`,
  copyright: `कॉपीराइट क्राउडफंडिंग`,
  td1: `वार्षिक दर`,
  td2: `प्रारंभिक निवेश राशि`,
  td3: `पूरा हुआ/कुल राशि`,
  warning: `*प्रमोशन के वास्तविक परिणामों के आधार पर, आय रोज़ाना बदलती है।`,
  schedule: `क्राउडफंडिंग की प्रगति`,
  buynow: `अभी खरीदें`,
  download: `डाउनलोड करें`,
  go: `जाएँ`,
  android: `एंड्रॉइड`,
  apple: `ऐप स्टोर`,
  Characteristic: `विशेषता`,
  Participation: `भागीदारी राशि`,
  method: `भुगतान विधि`,
  amount: `भुगतान राशि`,
  jump: `भुगतान पर जाएं`,
  name1: `मिनी-सीरीज`,
  name2: `कई प्रकार`,
  name3: `स्टार कार्ड सदस्यता`,
  name4: `आय का स्रोत`,
  describe1: `गुणवत्ता गारंटी`,
  describe2: `आपकी ज़रूरतों के अनुसार`,
  describe3: `अरबों की सब्सिडी`,
  describe4: `मुख्य कार्य`,
  warning1: `इनपुट खाली नहीं हो सकता`,
  success1: `सफलतापूर्वक बाइंड हुआ`,
  ends: `काउंटडाउन समाप्त`,
  warning2: `आप अभी लॉग इन नहीं हैं, कृपया पहले लॉग इन करें`,
}
